import { message, Space } from "antd";
import SpButton from "../../../sp-components/SpButton/SpButton";
import transactionInitImage from '../../../assets/images/service_interruption.png';
import { useState } from "react";
import { redirectAfterDelay, sleep } from "../../../utils/utils";

const PaymentCanceled = ({transactionReference, txData}) => {
    const [isLoading, setLoading] = useState(false);

    const handleRetry = () => {
        setLoading(true);

        if (txData) {
            sleep(1000).then(() => {
                redirectAfterDelay(txData?.payment_link);
            })
        }
        else {
           sleep(1000).then(() => {
            message.error("Unable to re-initiate transaction. Please try again in 2mins.");
            setLoading(false);
           })
        }
    }

    return (
        <div style={{
            backgroundColor: '#fff',
            padding: "1.2rem 2rem",
            borderRadius: 12,
            marginTop: 16,
        }}>

            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                minHeight: '33vh',
                textAlign: 'center',
                padding: "2rem 2rem",

            }}>

                <div style={{ marginBottom: 24 }}>
                    <img src={transactionInitImage} alt='' style={{
                        maxWidth: "205px"
                    }} />
                </div>
                <h4 style={{
                    fontSize: 20,
                }}>Payment cancelled</h4>
                <p 
                    style={{
                        color: "#696969",
                        maxWidth: "320px",
                        fontSize: 14,
                        textAlign: "center"
                    }}
                >
                    Your transaction with reference<br /> 
                    <span style={{ fontWeight: 600, color: "#696969" }}>{transactionReference}</span> was cancelled.
                </p>

                    <div style={{ marginTop: 40 }}>
                        <Space>
                            <SpButton onClick={handleRetry} loading={isLoading}>
                                Retry
                            </SpButton>

                            <SpButton type="outline" onClick={() => {
                                redirectAfterDelay('/');
                            }}>
                                Back to Home
                            </SpButton>
                        </Space>
                    </div>
            </div>
        </div>
    );
};

export default PaymentCanceled;
