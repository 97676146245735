import Title from "antd/es/typography/Title";
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";


const SpBackNav = ({ title, url, onBack }) => {
    const navigate = useNavigate();

    const handleBackNav = () => {
        if (typeof onBack === 'function') {
            onBack();
        }
        else {
            navigate(url?? -1);
        }
    }

    return (
        <div className="sp-backnav">
            <div className="sp-backnav__back-icon" onClick={handleBackNav}>
                <ion-icon name="arrow-back-outline"></ion-icon>
            </div>
            <Title className="sp-backnav__title" onClick={handleBackNav} level={4}>{title}</Title>
        </div>
    );
}

SpBackNav.propTypes = {
    title: PropTypes.string
}

SpBackNav.defaultProps = {
    title: "Go Back"
}

export default SpBackNav;