import { createReducer } from '@reduxjs/toolkit';
import {
  login,
  register,
  authWithPin,
} from '../actions/auth';
import { AUTH_WITH_PIN_SET, LOGIN_RESET, REGISTER_RESET } from './../types';

const initialState = {
  login: {
    loading: false,
    error: null,
    data: null
  },
  register: {
    loading: false,
    error: null,
    data: null
  },
  pinAuth: {
    loading: false,
    error: null,
    required: false
  }
};

const authReducer = createReducer(initialState, {
  // Login actions
  [login.trigger]: (state) => {
    state.login.loading = true;
    state.login.error = null;
    state.login.data = null;
  },
  [login.resolved]: (state, action) => {
    state.login.loading = false;
  },
  [login.rejected]: (state, action) => {
    state.login.loading = false;
    state.login.error = action.payload;
  },
  [LOGIN_RESET]: (state, action) => ({
    ...state,
    login: initialState.login
  }),

  // Re-validate login actions
  [AUTH_WITH_PIN_SET]: (state, action) => {
    if (!state.pinAuth) {
      state.pinAuth = {}; // Initialize if it doesn't exist
    }
    state.pinAuth.required = action.payload?.pinRequired;
  },
  [authWithPin.trigger]: (state) => {
    state.pinAuth.loading = true;
    state.pinAuth.error = null;
  },
  [authWithPin.resolved]: (state, action) => {
    state.pinAuth.loading = false;
    state.pinAuth.required = false;
    state.pinAuth.error = null;
  },
  [authWithPin.rejected]: (state, action) => {
    state.pinAuth.loading = false;
    state.pinAuth.error = action.payload;
  },

  // Register actions
  [register.trigger]: (state) => {
    state.register.loading = true;
    state.register.error = null;
  },
  [register.resolved]: (state, action) => {
    state.register.loading = false;
    state.register.data = null;
    state.register.error = null;
  },
  [register.rejected]: (state, action) => {
    state.register.loading = false;
    state.register.error = action.payload;
  },
  [REGISTER_RESET]: (state) => ({
    ...state,
    register: initialState.register
  }),
});

export default authReducer;
