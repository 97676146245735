import transactionInitImage from '../../../assets/images/transaction_failed.png';
import SapaButton from "../../../ui/Button/Button";
import { redirectAfterDelay } from "../../../utils/utils";

const TransactionFailed = ({ txData }) => {
    const {
        res
    } = txData || {};

    return (
        <div style={{
            backgroundColor: '#fff',
            padding: "1.2rem 2rem",
            borderRadius: 12,
            marginTop: 16,
        }}>

            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                minHeight: '33vh',
                textAlign: 'center',
                padding: "2rem 2rem",

            }}>

                <div style={{ marginBottom: 24 }}>
                    <img src={transactionInitImage} alt='' style={{
                        maxWidth: "205px"
                    }} />
                </div>
                <h4 style={{
                    fontSize: 20,
                }}>Transaction Failed</h4>
                <p style={{
                    color: "#696969",
                    maxWidth: "320px",
                    fontSize: 14,
                }}>
                    {/* The service provider is currently down. We will refund your payment to your Sapa Wallet. */}
                    {res?.message}
                </p>

                <div style={{ marginTop: "64px" }}>
                    <SapaButton
                        label="Back to Home"
                        buttonType="bordered"
                        onClick={() => {
                            redirectAfterDelay('/');
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default TransactionFailed;
