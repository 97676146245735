import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const SpButton = ({ children, htmlType, className, fullWidth, disabled, loading, ...props }) => (
  <Button
    disabled={disabled}
    htmlType={htmlType}
    size="large"
    shape="round"
    loading={loading}
    className={`sp-button ${disabled? 'disabled':''} ${className}${fullWidth ? ' full-width' : ''}`}
    {...props}
  >
    {children}
  </Button>
);

SpButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string, // Allow passing additional class names
  type: PropTypes.oneOf(['primary', 'default', 'outline', 'dashed', 'danger', 'link']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  htmlType: PropTypes.oneOf(['submit', 'reset', 'button']),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

SpButton.defaultProps = {
  className: '',
  type: "primary",
  htmlType: 'button',
  fullWidth: false,
  disabled: false,
  loading: false,
};

export default SpButton;
