import { createReducer } from '@reduxjs/toolkit';
import {
  fetchUser,
  updateUser,
  resetUserState,
  logout,
  requestAccount,
} from '../actions/user'; // Update import path as needed
import { login, refreshToken, register } from '../actions/auth';
import { verifyOtp } from '../actions/otpVerification';

const initialState = {
  data: null,
  loading: false,
  error: null,
  requestAccount: {}
};

const userReducer = createReducer(initialState, {
  // Fetch user actions
  [fetchUser.trigger]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [fetchUser.resolved]: (state, action) => {
    state.loading = false;
    state.data = {user: action.payload?.data};
    state.error = null;
  },
  [fetchUser.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  // Update user actions
  [updateUser.trigger]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [updateUser.resolved]: (state, action) => {
    state.loading = false;
    state.data = { ...state.data, ...action.payload };
    state.error = null;
  },
  [updateUser.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  // Local update and refresh token
  [refreshToken.resolved]: (state, action) => {
    state.data = {user: action.payload?.user};
  },
  [login.resolved]: (state, action) => {
    state.data = action.payload?.data?? {};
  },
  [register.resolved]: (state, action) => {
    state.data = {user: action.payload?.data?.user?? {}};
  },
  [verifyOtp.resolved]: (state, action) => {
    const {actionType, ...restData} = action.payload;

    if (actionType === "login_with_username_and_otp") {
      state.data = {user: restData?.user};
    }
    
  },
  // Logout actions
  [logout.trigger]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [logout.resolved]: () => initialState, // Reset state on successful logout
  [logout.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  // Request virtual account actions
  [requestAccount.trigger]: (state) => {
    state.requestAccount = {
      loading: true,
      error: null
    };
  },
  [requestAccount.resolved]: (state) => {
    state.requestAccount = {
      loading: false,
      error: null
    };
  },
  [requestAccount.rejected]: (state, action) => {
    state.requestAccount = {
      loading: false,
      error: action.payload
    };
  },

  // Reset state
  [resetUserState]: () => initialState,
});

export default userReducer;
