import SpCardList from "../../../sp-components/SpCardList/SpCardList";
import { useDispatch, useSelector } from "react-redux";
import { fetchServicesByCategory } from "../../../redux/actions/service";
import { useCallback, useEffect, useState } from "react";
import SpOptionButtonGroup from "../../../sp-components/SpOptionButtonGroup/SpOptionButtonGroup";
import SpInputButtonGroup from "../../../sp-components/SpInputButtonGroup/SpInputButtonGroup";
import SpInput from "../../../sp-components/SpInput/SpInput";
import { formatAmount } from '../../../helpers/numbers';
import SpMessage from "../../../sp-components/SpMessage/SpMessage";
import SpButton from "../../../sp-components/SpButton/SpButton";
import { useNavigate } from "react-router-dom";
import { updateTransactionFormData } from "../../../redux/actions/transactions";
import SpLoadingOverlay from "../../../sp-components/SpLoadingOverlay/SpLoadingOverlay";
import Title from "antd/es/typography/Title";
import * as yup from 'yup';
import { useFormik } from "formik";
import useDebounce from "../../../hooks/useDebounce";
import { validateAccount } from "../../../redux/actions/accountValidation";
import withUserData from "../../../HOC/withUserData";
import { sleep } from "../../../utils/utils";
import SpPageTransition from "../../../sp-components/SpPagination/SpPagination";

const accountTypes = [
  { label: 'PREPAID' },
  { label: 'POSTPAID' }
];

const disallowedAmountOptions = [20000, 50000];
const allowedAmountOptions = [500, 1000, 2000, 5000, 10000];

const validationSchema = yup.object({
  account: yup.string()
    .matches(/^\d+$/, 'Meter Number must be numeric')
    .required('Meter Number is required'),
  name: yup.string()
    .required('Account verification is required.'),
  account_type: yup.string()
    .required('Please select a valid meter type'),
  amount: yup.number()
    .required('Amount is required')
    .positive('Amount must be positive')
    .typeError('Amount must be numeric'),
});

const InputDetails = ({ loggedInAsGuest = true }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const {
    transactionFormData: formData = { account: "", account_type: "", service_type: "", amount: 0 }
  } = useSelector((state) => state.transaction);

  const {
    isLoading: accountValidationLoading,
  } = useSelector((state) => state.accountValidation);

  const {
    data: services,
    loading: servicesLoading
  } = useSelector(state => state.service.services);

  useEffect(() => {
    dispatch(fetchServicesByCategory({ category_slug: "electricity" }));
  }, [dispatch]);

  const renderCardItem = (item, isActive) => (
    <img src={item.image_url} alt={item.code} className={`sp-card__icon ${isActive ? 'active' : ''}`} />
  );

  const renderCardFooter = (item, isActive) => (
    <div className={`sp-card__title ${isActive ? 'active' : ''}`}>{item.code}</div>
  );

  const getAccountTypeIndexes = useCallback((accountTypes, selectedLabels) => {
    const lowerCaseSelectedLabels = selectedLabels.map(label => label?.toLowerCase());

    return accountTypes.reduce((acc, accountType, index) => {
      if (lowerCaseSelectedLabels.includes(accountType.label.toLowerCase())) {
        acc.push(index);
      }
      return acc;
    }, []);
  }, []);

  const getServiceCodeIndex = useCallback((services, selectedCode) => {
    return services.findIndex(service => service.code.toLowerCase() === selectedCode?.toLowerCase());
  }, []);

  const handleSubmit = useCallback((values) => {
    if (loggedInAsGuest && values.amount > Math.max(...disallowedAmountOptions)) {
      //message.error(`Please log in to purchase tokens worth more than NGN ${Math.max(...allowedAmountOptions)}.`);
      formik.setFieldError('amount', `Please log in to purchase tokens worth more than NGN ${Math.max(...allowedAmountOptions)}.`)
      return;
    }

    dispatch(updateTransactionFormData(values));

    setIsLoading(true);

    sleep(1000).then(() => {
      setIsLoading(false);
      navigate("payment-details");
    });

  }, [navigate, loggedInAsGuest]);

  const formik = useFormik({
    initialValues: formData,
    onSubmit: handleSubmit,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const accountSearch = useDebounce(formik.values.account);

  const handleChange = useCallback((field, value) => {
    dispatch(updateTransactionFormData({ [field]: value }));
    formik.setFieldValue(field, value);
    formik.setFieldTouched(field, true);
  }, [formik, dispatch]);

  const handleBlur = useCallback((field) => {
    formik.setFieldTouched(field, true);
  }, [formik]);

  useEffect(() => {
    if (accountSearch) {
      dispatch(validateAccount({
        service_type: 'electricity',
        service_code: formik.values.service.code,
        account_type: formik.values.account_type,
        account: formik.values.account,
      }))
        .then((res) => {
          formik.setFieldError('account', undefined);
          const data = { ...formik.values, ...res };
          formik.setValues(data);
        })
        .catch(err => formik.setFieldError('name', err || 'Validation failed. Please try again in 15mins!'));
    }
  }, [dispatch, accountSearch, formik.values.service?.code, formik.values.account_type]);


  const formIsValid = formik.isValid && !isLoading;


  return (
    <SpLoadingOverlay isLoading={servicesLoading}>
      <SpPageTransition>
        <form onSubmit={formik.handleSubmit}>
          <Title level={4}>Buy Electricity</Title>
          <p style={{ marginTop: 30 }}>Select Disco</p>
          <SpCardList
            items={services}
            renderContent={renderCardItem}
            renderFooter={renderCardFooter}
            onSelected={item => handleChange("service", item)}
            defaultSelectedIndex={getServiceCodeIndex(services, formik.values.service?.code)}
            isLoading={accountValidationLoading}
          />

          <div style={{ maxWidth: "400px", marginTop: 40 }}>
            <p>Account Type</p>
            <SpOptionButtonGroup
              buttons={accountTypes}
              onSelected={(item) => handleChange("account_type", item?.label)}
              defaultActiveIndexes={getAccountTypeIndexes(accountTypes, [formik.values.account_type])}
              isLoading={accountValidationLoading}
            />
          </div>

          <div style={{ maxWidth: "400px", marginTop: 40 }}>
            <p>Meter Number</p>
            <SpInput
              numericOnly
              value={formik.values.account}
              onChange={e => handleChange("account", e.target.value)}
              onBlur={() => handleBlur("account")}
              isLoading={accountValidationLoading}
            />
           <SpMessage type="success" visible={!!formik.values.name && !formik.errors.name}>
              {formik.values.name}
            </SpMessage>

            <SpMessage type="danger" visible={formik.errors.name && !accountValidationLoading}>
              {formik.errors.name}
            </SpMessage>

          </div>

          <div style={{ maxWidth: "400px", marginTop: 40 }}>
            <p>Amount</p>
            <SpInputButtonGroup
              selectedValue={formik.values.amount}
              options={[...allowedAmountOptions, ...disallowedAmountOptions]}
              disabledOptions={loggedInAsGuest ? disallowedAmountOptions : []}
              onChange={value => handleChange("amount", value)}
              multiSelect={false}
            />
            <div style={{ marginTop: 16 }}>
              <SpInput
                numericOnly
                value={formik.values.amount}
                onChange={e => handleChange("amount", e.target.value)}
                onBlur={() => handleBlur("amount")}
              />
              <SpMessage type='danger' visible={formik.touched.amount && formik.errors.amount}>
                {formik.errors.amount}
              </SpMessage>
              {!formik.errors.amount && (
                <p>
                  <span className="font-size-11px">MINIMUM AMOUNT:</span>
                  <span className="text-success font-weight-medium font-size-11px">
                    {formatAmount(Math.min(...allowedAmountOptions), '₦')}
                  </span>
                </p>
              )}
            </div>
          </div>

          <div style={{ maxWidth: "400px", marginTop: 40 }}>
            <SpButton loading={isLoading} disabled={!formIsValid} htmlType="submit">Continue</SpButton>
          </div>
        </form>
      </SpPageTransition>
    </SpLoadingOverlay>
  );
};

export default withUserData(InputDetails);
