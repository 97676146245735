import React, { useCallback } from "react";
import { message } from "antd";
import { Link } from "react-router-dom";
import { NAV_LOGIN } from "../../routes/routes.constant";
import { getNavPath } from "../../routes/routes.helpers";
import CopyIcon from "../../assets/svgs/main-copy.svg";
import withUserData from "../../HOC/withUserData";
import SpButton from "../../sp-components/SpButton/SpButton";
import useSpModal from "../../sp-components/SpModal/SpModal";
import { formatAmount } from "../../helpers/numbers";

const DashboardInfo = ({
  loggedInAsGuest = true,
  virtualAccounts = {},
  wallet = {},
  userData = {}
}) => {

  const accountName = userData?.displayName?? '';
  const { requestVirtualAccount } = useSpModal();

  const {
    //account_name: accountName = '',
    account_number: accountNumber = "",
    bank_name: bankName = "",
  } = virtualAccounts[0]?? {};

  const {
    main_balance: balance = 0,
  } = wallet?? {};


  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(accountNumber);
    message.success("Copied to clipboard");
  }, [accountNumber]);

  const handleRequestVirtualAccount = () => {
    requestVirtualAccount();
  }

  return (
    <div>
      {!loggedInAsGuest ? (
        <div className="row justify-space-between">
          <div>
            <div className="sub-text mb-xs">Hey {accountName}!</div>
            <div className="amount-text">{formatAmount(balance, '₦')}</div>
            {accountNumber? (
              <div className="account-text">
                {bankName} - <b>{accountNumber}</b>{" "}
                <img
                  style={{ cursor: "pointer" }}
                  className="ml-sm"
                  src={CopyIcon}
                  alt=""
                  onClick={handleCopy}
                />
              </div>
            ) : <div style={{marginTop: 16}}><SpButton type="outline" onClick={handleRequestVirtualAccount}>Create Virtual Account</SpButton></div>}
          </div>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.3">
              <path
                d="M6.62234 18.9451H7.9001H16.2348H17.5135C17.911 18.9451 18.2335 19.2676 18.2335 19.6651C18.2335 20.0625 17.911 20.3851 17.5135 20.3851H6.62234C6.2249 20.3851 5.90234 20.0625 5.90234 19.6651C5.90234 19.2676 6.2249 18.9451 6.62234 18.9451Z"
                fill="#9747FF"
              />
              <path
                opacity="0.4"
                d="M21.3312 5.70396C21.2909 5.34012 20.9827 5.06459 20.616 5.06459H18.2515C18.2842 4.52699 18.287 4.16892 18.287 4.09499C18.287 3.83004 18.073 3.61499 17.807 3.61499H6.19102C6.06334 3.61499 5.94142 3.66588 5.85118 3.75611C5.76094 3.8454 5.71102 3.96827 5.71102 4.09595C5.71102 4.16988 5.7139 4.52795 5.74654 5.06459H3.38206C3.01534 5.06459 2.70718 5.34012 2.66686 5.70396C2.64766 5.871 2.24158 9.81371 5.27038 11.8057C5.3923 11.8863 5.52862 11.9247 5.66494 11.9247C5.89918 11.9247 6.12862 11.8105 6.26782 11.6003C6.48574 11.2681 6.39454 10.8217 6.06142 10.6028C4.40446 9.51324 4.11742 7.52507 4.08382 6.50459H5.8819C6.25534 9.50843 7.45342 14.294 11.2771 14.8355V16.5202L9.02696 16.5206C8.81768 16.5206 8.63336 16.656 8.56904 16.8538L7.89992 18.9427H16.2347L15.5665 16.8547C15.5031 16.656 15.3188 16.5206 15.1095 16.5206L12.7171 16.5202V14.8374C16.5494 14.3036 17.7456 9.51132 18.1181 6.50459H19.9142C19.8806 7.52507 19.5936 9.51324 17.9366 10.6028C17.6035 10.8217 17.5123 11.2681 17.7302 11.6003C17.8694 11.8105 18.0989 11.9247 18.3331 11.9247C18.4694 11.9247 18.6058 11.8863 18.7277 11.8057C21.7565 9.81371 21.3504 5.871 21.3312 5.70396Z"
                fill="#9747FF"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5542 10.6359C12.5542 11.0334 12.2317 11.3559 11.8342 11.3559C11.4368 11.3559 11.1142 11.0334 11.1142 10.6359V7.81546L10.832 7.89802C10.4509 8.00938 10.0505 7.79147 9.93918 7.40939C9.82782 7.02827 10.0467 6.62794 10.4278 6.51658L11.6326 6.16427C11.8515 6.09995 12.0848 6.14314 12.2662 6.27946C12.4477 6.41578 12.5542 6.6289 12.5542 6.85546V10.6359Z"
                fill="#9747FF"
              />
            </g>
          </svg>
        </div>
      ) : (
        <div>
          <h3>Welcome, Guest</h3>
          <span>
            Please <Link to={getNavPath(NAV_LOGIN)}>login</Link> to view more
            details about your Sapa account.
          </span>
        </div>
      )}
    </div>
  );
}

export default withUserData(DashboardInfo);
