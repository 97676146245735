import { Space } from "antd";
import SpButton from "../../../sp-components/SpButton/SpButton";
import { useNavigate } from "react-router-dom";
import SpReceipt from "../../BillReceipt/SpReceipt";
import PrintScreen from "../../BillReceipt/PrintScreen";
import { useReactToPrint } from "react-to-print";
import { useRef, useState } from "react";
import { redirectAfterDelay } from "../../../utils/utils";


const TransactionSuccessful = ({ txData }) => {
    const navigate = useNavigate();
    const printRef = useRef();
    const [isLoading, setIsLoading] = useState(false);

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        documentTitle: `${txData?.transaction_reference}`,
        onBeforeGetContent: () => {
            setIsLoading(true);
        },
        onAfterPrint: () => {
            setIsLoading(false);
        }
    });

    return (
        <>
            <div style={{
                backgroundColor: '#fff',
                padding: "1.2rem 2rem",
                borderRadius: 12,
                marginTop: 16,
            }}>

                {
                    <>
                        <h3 style={{
                            fontWeight: 700,
                            fontSize: 22,
                            marginBottom: 16
                        }}>Payment Successful</h3>

                        <SpReceipt txData={txData} />

                        <div style={{ marginTop: 40 }}>
                            <Space>
                                <SpButton loading={isLoading} onClick={handlePrint}>Download Receipt</SpButton>
                                <SpButton type="outline" onClick={() => {
                                    redirectAfterDelay('/')
                                }}>
                                    Back to Home
                                </SpButton>
                            </Space>
                        </div>
                    </>
                }
            </div>

            <PrintScreen
                ref={printRef}
                txData={txData}
            />
        </>
    );
};

export default TransactionSuccessful;
