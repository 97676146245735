import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import Chevron from "../../assets/svgs/svg-icons/Chevron";
import BillReceipt from "../../components/BillReceipt/BillReceipt";
import SapaButton from "../../ui/Button/Button";
import { Dropdown, Menu, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { formatDate } from "../../helpers/date";
import { EmptyState } from "./heplers";
import { formatAmount } from "../../helpers/numbers";
import { getUserTransactions } from "../../api/user";
import SpPageTransition from "../../sp-components/SpPagination/SpPagination";
import { capitalize } from 'lodash';

const Transactions = () => {
  const [showReceipt, setShowReceipt] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [allTransactions, setAllTransactions] = useState([]);
  const [displayedTransactions, setDisplayedTransactions] = useState(6);
  const [fetching, setFetching] = useState(true);
  const [visible, setVisible] = useState(false);
  const [filter, setFilter] = useState({
    value: "all",
    label: "All",
  });

  const { value: filterValue, label } = filter;

  const handleButtonClick = () => {
    setVisible(!visible);
  };

  const menuItems = [
    { value: "all", label: "All" },
    { value: "success", label: "Successful" },
    { value: "failed", label: "Failed" },
    { value: "pending", label: "Pending" },
    { value: "refund", label: "Refund" },
    { value: "initiated", label: "Initiated" },
  ];

  const menu = (
    <Menu
      onClick={(e) => {
        const selectedItem = menuItems.find((item) => item.value === e.key);
        setFilter(selectedItem);
        setVisible(!visible);
      }}
    >
      {menuItems.map((item) => (
        <Menu.Item key={item.value}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );

  function handleVisibleChange(visible) {
    setVisible(visible);
  }


  useEffect(() => {
    const fetchUserTransactions = async () => {
      try {
        const response = await getUserTransactions();
        const transactions = response?.data?.data?.data ?? [];
        setAllTransactions(transactions);
        setFetching(false);
      } catch (error) {
        message.error(error?.message || "error fetching transactions");
        setFetching(false);
      }
    };
    fetchUserTransactions();
  }, []);

  const filteredTransactions = useMemo(() => {
    if (filterValue === "all") {
      return allTransactions;
    } else {
      return allTransactions?.filter(
        (transaction) => transaction.status === filterValue
      );
    }
  }, [allTransactions, filterValue]);

  const groupTransactionsByDate = () => {
    const groupedTransactions = {};
    for (const transaction of filteredTransactions.slice(
      0,
      displayedTransactions
    )) {
      const date = transaction.created_at.split("T")[0];
      if (groupedTransactions[date]) {
        groupedTransactions[date].push(transaction);
      } else {
        groupedTransactions[date] = [transaction];
      }
    }
    return groupedTransactions;
  };

  const groupedTransactions = groupTransactionsByDate();

  const handleChevronClick = (transaction) => {
    setSelectedTransaction(transaction);
    setShowReceipt(true);
  };

  const handleLoadMore = () => {
    if (displayedTransactions + 6 <= filteredTransactions?.length) {
      setDisplayedTransactions((prevCount) => prevCount + 6);
    } else if (displayedTransactions < filteredTransactions?.length) {
      setDisplayedTransactions(filteredTransactions?.length);
    } else {
      setDisplayedTransactions(6);
    }
  };

  const loader = useCallback(() => {
    const antIcon = <LoadingOutlined className="loader-icon" spin />;
    return (
      <div className="justify-content-center">
        <Spin indicator={antIcon} />
      </div>
    );
  }, []);

  return (
    <div className="services__transactions_container">
    {fetching ? (
      loader()
    ) : (
      <>
        {showReceipt ? (
          <div>
            <h3>{selectedTransaction.utilityType}</h3>
            <BillReceipt
              txData={selectedTransaction}
              onClick={() => setShowReceipt(false)}
            />
          </div>
        ) : (
          <>
            <h3>Transactions</h3>
            <SpPageTransition className="filter-container">
              <label className="filter-container_label">Filter Status</label>
              <button
                type="button"
                className="filter-container_button"
                onClick={handleButtonClick}
              >
                {label} <Chevron />
              </button>
              {visible && <div className="overlay" />}
              {visible ? (
                <Dropdown
                  overlay={menu}
                  visible={visible}
                  onVisibleChange={handleVisibleChange}
                  trigger={["click"]}
                >
                  <span></span>
                </Dropdown>
              ) : (
                ""
              )}
            </SpPageTransition>
            {filteredTransactions.length === 0 ? (
              <EmptyState />
            ) : (
              <>
                {Object.entries(groupedTransactions)?.map(
                  ([created_at, transactions]) => (
                    <div className="transactions_card" key={created_at}>
                      <label>{formatDate(created_at)}</label>
                      {transactions.map((transaction, i) => (
                        <SpPageTransition
                          className={`transactions_card-items ${i !== 0 ? "with-border-top" : ""
                            }`}
                          key={i}
                        >
                          <div className="transaction-label">
                            <span className="transaction-label-icon">
                              <img
                                src={transaction?.service?.image_url}
                                width={30}
                                alt='E'
                                style={{
                                  borderRadius: 50
                                }}
                              ></img>
                            </span>
                            <span className="transaction-label-amount">
                              <p className="amount">
                                {`₦ ${formatAmount(transaction.amount)}`}
                              </p>
                              <span>
                                <p className="transaction-id">
                                  {transaction.transaction_reference}
                                </p>
                                <p>.</p>
                                <p className="payment-method">
                                  {transaction.account}
                                </p>
                              </span>
                            </span>
                          </div>
                          
                          <div className="transaction-status">
                            <span
                              className={`status ${transaction.status.toLowerCase()}`}
                            >
                              {transaction.status.toLowerCase() === "refund"? 'Refunded' : capitalize(transaction.status)}
                            </span>
                            <span
                              onClick={() => handleChevronClick(transaction)}
                            >
                              <Chevron />
                            </span>


                          </div>
                        </SpPageTransition>
                      ))}
                    </div>
                  )
                )}
                {filteredTransactions?.length > 6 && (
                  <SapaButton
                    label={
                      displayedTransactions >= filteredTransactions.length
                        ? "Load Less"
                        : "Load More Transactions"
                    }
                    buttonType="bordered"
                    customClass="loadmore-btn"
                    onClick={handleLoadMore}
                  />
                )}
              </>
            )}
          </>
        )}
      </>
    )}
  </div>
  );
};

export default memo(Transactions);
