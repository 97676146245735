import React, { useEffect, useRef, useState } from "react";
import LceedataButton from "../../ui/Button/Button";

import PrintScreen from "./PrintScreen";
import { useReactToPrint } from "react-to-print";
import SpReceipt from "./SpReceipt";
import SpBackNav from "../../sp-components/SpBackNav/SpBackNav";
import useResolveTransaction from "../../hooks/useResolveTransaction";

function BillReceipt({
  onClick,
  txData
}) {
  const printRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(txData || {});
  const {data: resolvedData, isLoading: txResolving, resolveTransaction} = useResolveTransaction(data?.transaction_reference);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${txData?.transaction_reference}`,
    onBeforeGetContent: () => {
      setIsLoading(true);
    },
    onAfterPrint: () => {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    if (resolvedData) {
      setData(resolvedData);
    }
  }, [resolvedData])

  return (
    <div>

      <div style={{ marginBottom: 8 }}>
        <SpBackNav title="Transaction Details" onBack={onClick} />
      </div>

      <SpReceipt txData={data} />

      <div className="bill-receipt__buttons" style={{ marginTop: 32 }}>
        <LceedataButton loading={isLoading} label="Download Receipt" onClick={handlePrint} />

        {['initiated', 'pending'].includes(data?.status?.toLowerCase()) &&
          <LceedataButton 
            buttonType="bordered" 
            loading={txResolving} 
            label="Report Issue" 
            onClick={resolveTransaction} 
          />
        }
      </div>

      <PrintScreen
        ref={printRef}
        txData={data}
      />
    </div>
  );
}

export default BillReceipt;
