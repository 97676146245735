import { Modal } from 'antd';
import React, { useState } from 'react';

const ModalContent = ({ content: Content, onClose, ...props }) => {
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = (eventType, value = null) => {
    setIsClosing(true);
    setTimeout(() => {
      if (typeof onClose === 'function') {
        onClose(eventType, value);
      }
    }, 300); // Match the duration of the fade-out animation
  };

  const handleAnimationEnd = () => {
    if (isClosing) {
      onClose(); // Ensure onClose is invoked after animation ends
    }
  };

  return (
    <Modal
      open={true}
      footer={null}
      onCancel={() => handleClose("canceled")}
      closable={false}
      centered={true}
      maskClosable={false}
      className={`sp-modal ${isClosing ? "fade-out" : ""}`}
      afterClose={handleAnimationEnd}
      maskStyle={{ backdropFilter: 'blur(4px)', WebkitBackdropFilter: 'blur(4px)' }} // Apply the blur effect here
    >
      <div className="sp-modal__wrapper">
        <div className="sp-modal__header">
          <button className="sp-modal__close-btn" onClick={() => handleClose("canceled")}>
            &times;
          </button>
        </div>
        {Content && <Content onClick={(value) => handleClose("ok", value)} {...props} />}
      </div>
    </Modal>
  );
};

export default ModalContent;
