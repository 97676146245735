import React from 'react';
import { Steps } from 'antd';
import PropTypes from 'prop-types';

const SpSteps = ({ items, current = 0, ...rest }) => (
    <Steps
        className="sp-steps"
        current={current}
        items={items}
        {...rest}
    />
);


SpSteps.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            description: PropTypes.string,
        })
    ).isRequired,
    current: PropTypes.number,
};

export default SpSteps;
