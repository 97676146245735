import React from "react";
import PropTypes from "prop-types";
import { Col } from "antd";

// const ServiceTile = ({ label, icon, bgColor, url, onClick }) => {
//   return (
//     <div
//       className="service_tile"
//       style={{ background: bgColor }}
//       onClick={onClick}
//     >
//       <img style={{ width: "50%" }} src={icon} alt="" />
//       <span className="service_tile__text">{label}</span>
//     </div>
//   );
// };

const ServiceTile = ({ label, icon, bgColor, url, onClick, isActive = false }) => {
  return (
    <Col span={8}>
      {" "}
      <div
        className="service_tile"
        style={{ background: bgColor, opacity: isActive? 0.8 : 0.3 }}
        onClick={() => onClick(label, url)}
      >
        <img className="service_tile__icon" src={icon} alt=""  />
        <span className="service_tile__text">{label}</span>
      </div>
    </Col>
  );
};

ServiceTile.propTypes = {
  label: PropTypes.string,
};

export default ServiceTile;
