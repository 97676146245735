import React, { createContext, memo, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import SapaModal from "./components/Modal/Modal";
import { getModalVisible } from "./redux/selectors/modal";
import router from "./routes/root";

const ReachableContext = createContext(null);
const UnreachableContext = createContext(null);

const MainApp = () => {
  const isModalVisible = useSelector(getModalVisible, shallowEqual);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Add the "loading" class to body when isLoading is true
    if (isLoading) {
      document.body.classList.add('loading');
    } else {
      document.body.classList.remove('loading');
    }

    // Wait for the DOMContentLoaded event instead of using a timer
    const handleDomContentLoaded = () => {
      setIsLoading(false); // Stop the loader once the DOM is fully loaded
    };

    // Check if DOM is already loaded or listen for the DOMContentLoaded event
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
      // If DOM is already loaded, set loading to false
      setIsLoading(false);
    } else {
      // Add event listener to detect DOMContentLoaded
      window.addEventListener('DOMContentLoaded', handleDomContentLoaded);
    }

    return () => {
      window.removeEventListener('DOMContentLoaded', handleDomContentLoaded);
      document.body.classList.remove('loading');
    };
  }, [isLoading]);

  return (
    <ReachableContext.Provider value="Light">
      <SapaModal visible={isModalVisible} />
      {!isLoading && <RouterProvider router={router} />}
      <UnreachableContext.Provider value="Bamboo" />
      <div className="spinner"></div>
    </ReachableContext.Provider>
  );
};

export default memo(MainApp);
