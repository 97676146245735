import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SpOptionButtonGroup from './../SpOptionButtonGroup/SpOptionButtonGroup';
import { paymentOptions, paymentOptionsForGuest } from '../../constants';
import withUserData from '../../HOC/withUserData';

const SpPaymentOptions = ({ loggedInAsGuest = true, title, items, onSelected, defaultOptionIndexes, filterOutLabels }) => {
    const [options, setOptions] = useState(items);
    const [selectedIndexes, setSelectedIndexes] = useState(defaultOptionIndexes);

    useEffect(() => {
        const availableOptions = loggedInAsGuest ? paymentOptionsForGuest : paymentOptions;
        const filteredOptions = filterOutLabels && filterOutLabels.length > 0 
            ? availableOptions.filter(option => !filterOutLabels
                .map(label => label.toLowerCase())
                .includes(option.label.toLowerCase())
              )
            : availableOptions;
        
        setOptions(filteredOptions);
    }, [loggedInAsGuest, filterOutLabels]);

    const handleSelection = (item, selectedIndex) => {
        setSelectedIndexes([selectedIndex]);

        if (typeof onSelected === 'function') {
            onSelected(selectedIndex, item);
        }
    };

    return (
        <div>
            <p>{title}</p>
            <div>
                <SpOptionButtonGroup
                    buttons={options}
                    direction="column"
                    onSelected={handleSelection}
                    className='opacity-filter'
                />
            </div>
        </div>
    );
};

SpPaymentOptions.propTypes = {
    title: PropTypes.node,
    onSelected: PropTypes.func,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            icon: PropTypes.node,
            disabled: PropTypes.bool,
        })
    ),
    defaultOptionIndexes: PropTypes.arrayOf(PropTypes.number),
    filterOutLabels: PropTypes.arrayOf(PropTypes.string), // Accepts an array of strings
};

SpPaymentOptions.defaultProps = {
    title: "Payment Options",
    items: [],
    defaultOptionIndexes: [],
    onSelected: () => {},
    filterOutLabels: [], // Default value
};

export default withUserData(SpPaymentOptions);
