import React from 'react';

export const WalletIcon = ({ width = 20, height = 20, fill = "#2C264B", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13.7618 2.5C16.6337 2.5 18.3307 4.14885 18.3307 6.94822V6.97927H14.8018C13.1596 6.98216 11.8292 8.27929 11.8263 9.88047C11.824 11.4853 13.1559 12.7882 14.8018 12.7903H18.3337V13.0453C18.3337 15.8446 16.6366 17.5 13.7648 17.5H6.23662C3.36403 17.5 1.66699 15.8446 1.66699 13.0453V6.94822C1.66699 4.14885 3.36403 2.5 6.23662 2.5H13.7618ZM10.3196 5.72909H5.61662C5.26699 5.73054 4.98329 6.00715 4.9818 6.34876C4.98033 6.69182 5.26477 6.97133 5.61662 6.97277H10.3255C10.6774 6.97133 10.9618 6.69182 10.9603 6.34804C10.9588 6.00498 10.6714 5.72765 10.3196 5.72909Z" fill={fill} />
    <path opacity="0.4" d="M13.3652 10.2472C13.5394 11.0399 14.2344 11.5976 15.0278 11.5831H17.7361C18.0662 11.5831 18.334 11.3097 18.334 10.9717V8.86208C18.3333 8.52485 18.0662 8.25073 17.7361 8.25H14.9641C14.0615 8.2529 13.3326 9.00204 13.334 9.92523C13.334 10.0333 13.3446 10.1413 13.3652 10.2472Z" fill={fill} />
    <path d="M15.0003 10.75C15.4606 10.75 15.8337 10.3769 15.8337 9.91668C15.8337 9.45644 15.4606 9.08334 15.0003 9.08334C14.5401 9.08334 14.167 9.45644 14.167 9.91668C14.167 10.3769 14.5401 10.75 15.0003 10.75Z" fill={fill} />
  </svg>
);

export const BankTransferIcon = ({ width = 20, height = 20, fill = "#2C264B", ...props }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill={fill}
      fillRule="evenodd"
      d="M16.73 6.328 12.018 2.49a3.111 3.111 0 0 0-3.923 0l-4.71 3.837a3.082 3.082 0 0 0-1.146 2.402v6.07a3.412 3.412 0 0 0 3.408 3.407h8.819a3.412 3.412 0 0 0 3.408-3.407v-6.07a3.08 3.08 0 0 0-1.144-2.401Z"
      clipRule="evenodd"
      opacity={0.4}
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M12.7 14.392a.688.688 0 0 1-.684.688h-3.92a.687.687 0 0 1-.684-.688v-2.64a2.643 2.643 0 1 1 5.289 0v2.64Z"
      clipRule="evenodd"
    />
  </svg>
);


export const BankCardIcon = ({ width = 20, height = 20, fill = "#2C264B", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.31 6.01049C14.31 4.18809 13.1372 2.96249 11.3932 2.96249H4.99555C3.24995 2.96249 2.07715 4.18809 2.07715 6.01049C2.07715 6.07677 2.13088 6.1305 2.19715 6.1305H14.19C14.2562 6.1305 14.31 6.07677 14.31 6.01049Z" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M17.5279 8.20566C17.1303 7.53846 16.4727 7.08966 15.6255 6.90806C15.4926 6.87926 15.3671 6.9805 15.3671 7.11645V10.3857C15.3671 12.6513 13.8383 14.2337 11.6479 14.2337H5.68846C5.50529 14.2337 5.39259 14.4295 5.50459 14.5745C5.90056 15.087 6.48174 15.4597 7.21423 15.6177L13.4687 16.9593C13.7111 17.0113 13.9479 17.0369 14.1767 17.0369C15.5519 17.0361 16.6303 16.1193 16.9583 14.5897L17.8367 10.4993C18.0191 9.64489 17.9127 8.85209 17.5279 8.20566Z" fill={fill} />
    <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M14.31 10.1953V7.57124C14.31 7.43869 14.2025 7.33124 14.07 7.33124H2.31715C2.1846 7.33124 2.07715 7.43869 2.07715 7.57124V10.1953C2.07715 12.0177 3.24995 13.2433 4.99555 13.2433H11.3924C13.1372 13.2433 14.31 12.0177 14.31 10.1953Z" fill={fill} />
  </svg>
);


export const CopyIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.77694 2.23897C3.22117 1.76175 3.84977 1.5 4.57718 1.5H8.04193C8.68333 1.5 9.24873 1.70147 9.67967 2.07935C10.1091 2.45587 10.3737 2.97994 10.4656 3.57221C10.5079 3.84509 10.321 4.10061 10.0481 4.14291C9.7752 4.18521 9.51967 3.99829 9.4774 3.72541C9.4166 3.33341 9.2506 3.03307 9.0204 2.83125C8.7918 2.6308 8.46873 2.5 8.04193 2.5H4.57718C4.09841 2.5 3.74506 2.66661 3.50889 2.92033C3.26905 3.17797 3.11328 3.56573 3.11328 4.06699V8.53027C3.11328 8.98693 3.24317 9.34987 3.44735 9.60407C3.6483 9.85427 3.94371 10.0282 4.33819 10.0771C4.61223 10.1111 4.80684 10.3609 4.77285 10.6349C4.73887 10.9089 4.48916 11.1035 4.21512 11.0695C3.5764 10.9903 3.04012 10.694 2.66769 10.2303C2.29848 9.7706 2.11328 9.17607 2.11328 8.53027V4.06699C2.11328 3.35799 2.33637 2.71225 2.77694 2.23897Z"
      fill="#AC6CFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.1598 5.64628C6.60417 5.16968 7.2328 4.90884 7.96 4.90884H11.4236C12.1525 4.90884 12.7814 5.16944 13.2256 5.64656C13.666 6.11963 13.8875 6.76492 13.8875 7.47292V11.9362C13.8875 12.6442 13.666 13.2895 13.2255 13.7626C12.7812 14.2397 12.1521 14.5003 11.423 14.5003H7.96C7.23107 14.5003 6.60219 14.2397 6.15801 13.7625C5.71761 13.2895 5.49609 12.6442 5.49609 11.9362V7.47292C5.49609 6.76419 5.71905 6.11902 6.1598 5.64628ZM6.8912 6.32822C6.65171 6.58511 6.49609 6.97192 6.49609 7.47292V11.9362C6.49609 12.4379 6.65111 12.8246 6.88993 13.0811C7.125 13.3337 7.47807 13.5003 7.96 13.5003H11.423C11.9053 13.5003 12.2585 13.3336 12.4936 13.0811C12.7325 12.8246 12.8875 12.4379 12.8875 11.9362V7.47292C12.8875 6.97126 12.7325 6.58449 12.4937 6.32794C12.2586 6.07544 11.9055 5.90884 11.4236 5.90884H7.96C7.48047 5.90884 7.12713 6.07521 6.8912 6.32822Z"
      fill="#AC6CFF"
    />
  </svg>
);




export const BusinessIdeaISvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <mask
      id="a"
      width={206}
      height={205}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "alpha",
      }}
    >
      <rect width={205} height={205} x={0.5} fill="#D9D9D9" rx={102.5} />
    </mask>
    <g mask="url(#a)">
      <path
        fill="#090320"
        d="M119.812 96.998s2.882-10.064 2.296-13.434c-.587-3.371 4.445-11.725 14.118-7.524 9.722 4.201 25.16 9.233 26.235 11.041s-.244 2.15 1.319 3.615c1.563 1.417 17.245 14.608-10.064 33.905z"
      />
      <path
        fill="#16084E"
        d="M73.743 147.709s7.035-8.354 19.688-8.208c12.653.147 17.196 3.469 17.196 3.469s.88-3.225 1.515-5.081 3.468-1.954 5.325-1.075c1.856.88 27.7 7.915 27.7 7.915s1.71.537 1.71 2.149-1.71 5.179-.977 6.156c.781.977 18.955 5.178 21.202 5.423 2.296.195 10.309 3.224 13.533 11.822 3.224 8.55 9.868 36.934 9.868 36.934l-155.355-.196 7.035-21.105z"
      />
      <path
        fill="#fff"
        d="M117.271 207.555c-.049 0-.146 0-.195-.049-.342-.098-.489-.489-.391-.782l9.966-29.947c.098-.342.489-.489.782-.391.342.098.488.488.391.782l-9.967 29.947c-.048.293-.293.44-.586.44M150.932 207.555a.646.646 0 0 1-.635-.635l.098-26.137c0-.342.293-.635.635-.635s.635.293.635.635l-.098 26.137a.646.646 0 0 1-.635.635M73.01 208.043a.676.676 0 0 1-.636-.537l-2.002-17.636c-.05-.342.195-.635.537-.684s.635.195.684.537l2.003 17.636c.049.342-.196.635-.538.684 0-.049-.048 0-.048 0"
      />
      <path
        fill="#8B4500"
        d="M118.2 128.069s1.563 6.693-1.71 9.576c-3.273 2.882 22.473 8.598 22.473 8.598l9.575-23.45z"
      />
      <path
        fill="#16084E"
        d="m139.353 146.976-.537-.098c-.049 0-5.569-1.221-11.139-2.833-10.21-2.932-11.822-4.495-12.116-5.521-.048-.293-.097-.83.44-1.319 2.931-2.589 1.515-8.891 1.515-8.94l-.147-.635 32.097-5.618zm-20.421-18.369c.293 1.807.88 6.986-2.052 9.526l-.097.098c.146 1.319 10.064 4.69 21.789 7.279l8.94-21.935z"
      />
      <path
        fill="#090320"
        d="m139.353 146.976-.537-.098c-.049 0-5.569-1.221-11.139-2.833-10.21-2.932-11.822-4.495-12.116-5.521-.048-.293-.097-.83.44-1.319 2.931-2.589 1.515-8.891 1.515-8.94l-.147-.635 32.097-5.618zm-20.421-18.369c.293 1.807.88 6.986-2.052 9.526l-.097.098c.146 1.319 10.064 4.69 21.789 7.279l8.94-21.935z"
      />
      <path
        fill="#8B4500"
        d="M129.29 83.466s9.038 14.656 21.544 15.145c0 0-3.664 6.204-2.052 10.161 1.564 3.957-3.126 8.598-3.126 8.598l2.491 1.417 4.202-6.058s2.149-1.661 4.885 1.173 1.173 9.038-3.224 11.09-6.205-.587-6.205-.587-5.716 6.205-10.454 7.475c-4.739 1.221-30.046.831-28.482-6.693 1.563-7.523 2.491-14.07 4.152-18.467 1.613-4.348 16.269-23.254 16.269-23.254"
      />
      <path
        fill="#16084E"
        d="M130.071 133.101c-7.181 0-17.05-1.026-20.567-4.348-1.124-1.074-1.515-2.296-1.271-3.615.44-2.198.831-4.25 1.222-6.253.928-4.886 1.758-9.136 2.931-12.262 1.661-4.446 15.829-22.669 16.415-23.45l.537-.733.489.782c.097.146 8.989 14.363 21.007 14.851l1.026.049-.537.928c-.049.05-3.469 6.009-2.052 9.624 1.368 3.469-1.466 7.231-2.736 8.696l1.27.733 3.957-5.667.098-.049c.879-.684 3.273-1.319 5.716 1.222 1.27 1.319 1.807 3.371 1.417 5.618-.538 2.833-2.345 5.325-4.788 6.449-3.469 1.661-5.569.537-6.449-.245-1.465 1.515-6.155 6.058-10.259 7.182-1.221.244-4.006.488-7.426.488m-.782-48.56c-2.638 3.468-14.265 18.613-15.682 22.424-1.172 3.077-1.954 7.23-2.882 12.066-.391 2.003-.782 4.104-1.221 6.303-.196.928.097 1.709.879 2.442 4.494 4.251 22.766 4.593 26.821 3.518 4.494-1.173 10.064-7.231 10.112-7.279l.538-.587.44.635c.048.098 1.612 2.15 5.422.391 2.101-.977 3.664-3.126 4.104-5.569.342-1.857-.049-3.518-1.075-4.544-2.052-2.1-3.664-1.367-4.006-1.123l-4.494 6.4-3.713-2.101.586-.586c.049-.049 4.397-4.397 2.98-7.915-1.319-3.322.684-7.963 1.661-9.868-4.983-.489-10.064-3.176-14.9-7.866-2.736-2.735-4.739-5.471-5.57-6.741"
      />
      <path
        fill="#090320"
        d="M130.071 133.101c-7.181 0-17.05-1.026-20.567-4.348-1.124-1.074-1.515-2.296-1.271-3.615.44-2.198.831-4.25 1.222-6.253.928-4.886 1.758-9.136 2.931-12.262 1.661-4.446 15.829-22.669 16.415-23.45l.537-.733.489.782c.097.146 8.989 14.363 21.007 14.851l1.026.049-.537.928c-.049.05-3.469 6.009-2.052 9.624 1.368 3.469-1.466 7.231-2.736 8.696l1.27.733 3.957-5.667.098-.049c.879-.684 3.273-1.319 5.716 1.222 1.27 1.319 1.807 3.371 1.417 5.618-.538 2.833-2.345 5.325-4.788 6.449-3.469 1.661-5.569.537-6.449-.245-1.465 1.515-6.155 6.058-10.259 7.182-1.221.244-4.006.488-7.426.488m-.782-48.56c-2.638 3.468-14.265 18.613-15.682 22.424-1.172 3.077-1.954 7.23-2.882 12.066-.391 2.003-.782 4.104-1.221 6.303-.196.928.097 1.709.879 2.442 4.494 4.251 22.766 4.593 26.821 3.518 4.494-1.173 10.064-7.231 10.112-7.279l.538-.587.44.635c.048.098 1.612 2.15 5.422.391 2.101-.977 3.664-3.126 4.104-5.569.342-1.857-.049-3.518-1.075-4.544-2.052-2.1-3.664-1.367-4.006-1.123l-4.494 6.4-3.713-2.101.586-.586c.049-.049 4.397-4.397 2.98-7.915-1.319-3.322.684-7.963 1.661-9.868-4.983-.489-10.064-3.176-14.9-7.866-2.736-2.735-4.739-5.471-5.57-6.741"
      />
      <path
        fill="#16084E"
        d="M140.526 99.832a.6.6 0 0 1-.489-.244c-3.566-4.886-8.354-2.59-8.549-2.492a.603.603 0 0 1-.831-.293.603.603 0 0 1 .293-.83c.049-.05 5.912-2.883 10.162 2.882.195.293.147.684-.147.88-.195.048-.293.097-.439.097"
      />
      <path
        fill="#090320"
        d="M140.526 99.832a.6.6 0 0 1-.489-.244c-3.566-4.886-8.354-2.59-8.549-2.492a.603.603 0 0 1-.831-.293.603.603 0 0 1 .293-.83c.049-.05 5.912-2.883 10.162 2.882.195.293.147.684-.147.88-.195.048-.293.097-.439.097"
      />
      <path
        fill="#16084E"
        d="M125.821 97.292a.616.616 0 0 1-.635-.636c.048-1.026-.196-1.807-.684-2.247-.587-.537-1.368-.488-1.368-.488-.342.048-.635-.245-.684-.587s.244-.635.586-.684c.049 0 1.319-.097 2.345.83.782.734 1.124 1.808 1.075 3.225 0 .293-.293.587-.635.587"
      />
      <path
        fill="#090320"
        d="M125.821 97.292a.616.616 0 0 1-.635-.636c.048-1.026-.196-1.807-.684-2.247-.587-.537-1.368-.488-1.368-.488-.342.048-.635-.245-.684-.587s.244-.635.586-.684c.049 0 1.319-.097 2.345.83.782.734 1.124 1.808 1.075 3.225 0 .293-.293.587-.635.587"
      />
      <path
        fill="#fff"
        fillOpacity={0.5}
        d="M133.686 110.726a6.546 6.546 0 0 0 6.546-6.546 6.545 6.545 0 1 0-13.092 0 6.546 6.546 0 0 0 6.546 6.546"
      />
      <path
        fill="#16084E"
        d="M133.686 111.361c-3.957 0-7.182-3.224-7.182-7.181s3.225-7.182 7.182-7.182 7.181 3.225 7.181 7.182-3.224 7.181-7.181 7.181m0-13.092a5.9 5.9 0 0 0-5.911 5.911 5.9 5.9 0 0 0 5.911 5.911 5.9 5.9 0 0 0 5.911-5.911 5.9 5.9 0 0 0-5.911-5.911"
      />
      <path
        fill="#090320"
        d="M133.686 111.361c-3.957 0-7.182-3.224-7.182-7.181s3.225-7.182 7.182-7.182 7.181 3.225 7.181 7.182-3.224 7.181-7.181 7.181m0-13.092a5.9 5.9 0 0 0-5.911 5.911 5.9 5.9 0 0 0 5.911 5.911 5.9 5.9 0 0 0 5.911-5.911 5.9 5.9 0 0 0-5.911-5.911"
      />
      <path
        fill="#fff"
        fillOpacity={0.5}
        d="M118.786 103.936a5.862 5.862 0 1 0 0-11.724 5.862 5.862 0 0 0 0 11.724"
      />
      <path
        fill="#16084E"
        d="M118.786 104.522a6.495 6.495 0 0 1-6.498-6.498 6.464 6.464 0 0 1 6.498-6.497 6.494 6.494 0 0 1 6.497 6.497 6.495 6.495 0 0 1-6.497 6.498m0-11.676a5.233 5.233 0 0 0-5.228 5.227 5.233 5.233 0 0 0 5.228 5.227 5.233 5.233 0 0 0 5.227-5.227 5.233 5.233 0 0 0-5.227-5.227"
      />
      <path
        fill="#090320"
        d="M118.786 104.522a6.495 6.495 0 0 1-6.498-6.498 6.464 6.464 0 0 1 6.498-6.497 6.494 6.494 0 0 1 6.497 6.497 6.495 6.495 0 0 1-6.497 6.498m0-11.676a5.233 5.233 0 0 0-5.228 5.227 5.233 5.233 0 0 0 5.228 5.227 5.233 5.233 0 0 0 5.227-5.227 5.233 5.233 0 0 0-5.227-5.227"
      />
      <path
        fill="#16084E"
        d="m140.646 105.523-.62 1.108 12.028 6.719.619-1.108z"
      />
      <path
        fill="#090320"
        d="m140.646 105.523-.62 1.108 12.028 6.719.619-1.108z"
      />
      <path
        fill="#16084E"
        d="M135.447 104.096c.839-1.091.946-2.415.24-2.958-.705-.543-1.958-.098-2.796.993-.839 1.09-.947 2.415-.241 2.957.706.543 1.958.099 2.797-.992"
      />
      <path
        fill="#090320"
        d="M135.447 104.096c.839-1.091.946-2.415.24-2.958-.705-.543-1.958-.098-2.796.993-.839 1.09-.947 2.415-.241 2.957.706.543 1.958.099 2.797-.992"
      />
      <path
        fill="#16084E"
        d="M122.5 98.495c.641-.8.734-1.791.207-2.213-.526-.421-1.473-.114-2.114.686s-.733 1.791-.207 2.213c.527.421 1.473.114 2.114-.686"
      />
      <path
        fill="#090320"
        d="M122.5 98.495c.641-.8.734-1.791.207-2.213-.526-.421-1.473-.114-2.114.686s-.733 1.791-.207 2.213c.527.421 1.473.114 2.114-.686"
      />
      <path
        fill="#8B4500"
        d="M128.068 94.556s-10.357 10.747-10.504 12.018c-.147 1.221 3.371 5.471 3.371 5.471"
      />
      <path
        fill="#16084E"
        d="M120.935 112.68c-.195 0-.342-.097-.488-.244-3.371-4.006-3.615-5.52-3.518-5.96.196-1.319 7.524-9.087 10.699-12.36a.62.62 0 0 1 .88 0 .62.62 0 0 1 0 .88c-4.886 5.08-10.064 10.796-10.357 11.675-.049.489 1.465 2.785 3.273 4.935.244.244.195.684-.098.879a.44.44 0 0 1-.391.195"
      />
      <path
        fill="#090320"
        d="M120.935 112.68c-.195 0-.342-.097-.488-.244-3.371-4.006-3.615-5.52-3.518-5.96.196-1.319 7.524-9.087 10.699-12.36a.62.62 0 0 1 .88 0 .62.62 0 0 1 0 .88c-4.886 5.08-10.064 10.796-10.357 11.675-.049.489 1.465 2.785 3.273 4.935.244.244.195.684-.098.879a.44.44 0 0 1-.391.195"
      />
      <path
        fill="#16084E"
        d="M124.892 117.957c-3.077 0-6.644-1.368-6.79-1.417-.342-.147-.489-.489-.342-.831s.488-.488.83-.342c.049 0 5.13 2.003 8.061 1.124a.63.63 0 0 1 .782.44.626.626 0 0 1-.44.781c-.684.147-1.417.245-2.101.245"
      />
      <path
        fill="#090320"
        d="M124.892 117.957c-3.077 0-6.644-1.368-6.79-1.417-.342-.147-.489-.489-.342-.831s.488-.488.83-.342c.049 0 5.13 2.003 8.061 1.124a.63.63 0 0 1 .782.44.626.626 0 0 1-.44.781c-.684.147-1.417.245-2.101.245"
      />
      <path
        fill="#16084E"
        d="m124.732 97.76-.671 1.077 3.731 2.326.672-1.078z"
      />
      <path
        fill="#090320"
        d="m124.732 97.76-.671 1.077 3.731 2.326.672-1.078z"
      />
      <path
        fill="#8B4500"
        d="M142.285 94.067s-4.006 4.348-1.613 9.673 3.909 6.302 3.909 7.67-1.466 6.547-1.466 6.547-12.555-5.032-14.265 8.061c0 0-3.909 2.393-1.368 6.351 0 0-2.736 2.296-1.954 6.986.781 4.69 3.566 2.882 3.566 2.882s-.928 10.894 4.739 13.191l-6.791 22.082 24.232 3.419s4.934-68.737 4.055-69.91c-.831-1.221-13.044-16.952-13.044-16.952"
      />
      <path
        fill="#16084E"
        d="m151.86 181.613-25.599-3.615 6.839-22.277c-4.543-2.345-4.739-9.869-4.69-12.605-.44.098-.977.098-1.466-.146-1.025-.489-1.709-1.661-2.051-3.566-.635-3.909 1.026-6.303 1.807-7.182-.635-1.221-.879-2.394-.586-3.517.391-1.662 1.612-2.687 2.15-3.078.537-3.86 2.1-6.547 4.592-8.012 3.615-2.15 8.158-1.026 9.819-.489.44-1.71 1.222-4.836 1.222-5.716 0-.439-.293-.879-.88-1.856-.684-1.075-1.709-2.687-2.98-5.521-2.54-5.667 1.564-10.161 1.71-10.357l.489-.537.439.586c.489.635 12.263 15.731 13.093 16.904.245.39.684.928-1.612 35.809-1.124 17.148-2.345 34.345-2.345 34.54zm-23.987-4.641 22.815 3.225c1.905-26.675 4.543-66.295 4.103-68.933-1.075-1.466-10.259-13.289-12.506-16.171-.929 1.32-2.736 4.543-1.026 8.403 1.221 2.736 2.198 4.299 2.882 5.374s1.075 1.71 1.075 2.54c0 1.417-1.319 6.156-1.466 6.693l-.195.684-.635-.244c-.049 0-5.472-2.101-9.38.195-2.199 1.319-3.567 3.762-4.055 7.377l-.049.293-.244.147s-1.466.928-1.857 2.491c-.195.929 0 1.955.684 2.981l.293.439-.439.342c-.098.098-2.443 2.15-1.759 6.4.244 1.417.684 2.296 1.27 2.589.635.293 1.319-.146 1.319-.146l1.075-.635-.098 1.221c0 .098-.83 10.455 4.348 12.507l.538.195z"
      />
      <path
        fill="#090320"
        d="m151.86 181.613-25.599-3.615 6.839-22.277c-4.543-2.345-4.739-9.869-4.69-12.605-.44.098-.977.098-1.466-.146-1.025-.489-1.709-1.661-2.051-3.566-.635-3.909 1.026-6.303 1.807-7.182-.635-1.221-.879-2.394-.586-3.517.391-1.662 1.612-2.687 2.15-3.078.537-3.86 2.1-6.547 4.592-8.012 3.615-2.15 8.158-1.026 9.819-.489.44-1.71 1.222-4.836 1.222-5.716 0-.439-.293-.879-.88-1.856-.684-1.075-1.709-2.687-2.98-5.521-2.54-5.667 1.564-10.161 1.71-10.357l.489-.537.439.586c.489.635 12.263 15.731 13.093 16.904.245.39.684.928-1.612 35.809-1.124 17.148-2.345 34.345-2.345 34.54zm-23.987-4.641 22.815 3.225c1.905-26.675 4.543-66.295 4.103-68.933-1.075-1.466-10.259-13.289-12.506-16.171-.929 1.32-2.736 4.543-1.026 8.403 1.221 2.736 2.198 4.299 2.882 5.374s1.075 1.71 1.075 2.54c0 1.417-1.319 6.156-1.466 6.693l-.195.684-.635-.244c-.049 0-5.472-2.101-9.38.195-2.199 1.319-3.567 3.762-4.055 7.377l-.049.293-.244.147s-1.466.928-1.857 2.491c-.195.929 0 1.955.684 2.981l.293.439-.439.342c-.098.098-2.443 2.15-1.759 6.4.244 1.417.684 2.296 1.27 2.589.635.293 1.319-.146 1.319-.146l1.075-.635-.098 1.221c0 .098-.83 10.455 4.348 12.507l.538.195z"
      />
      <path
        fill="#8B4500"
        d="M16.583 113.462c1.026 2.003 33.514 36.983 33.514 36.983l-7.28 36.738s17.686 11.187 26.87 3.713l1.857-24.818s10.064-20.079 6.693-28.775-11.92-24.427-11.432-27.798-5.716-4.983-5.96 0c-.245 4.983 2.1 19.737 2.1 19.737l-14.705-.293s-1.66-13.875-3.42-15.975c-1.807-2.101-5.178-3.518-5.178-.733s.195 9.331.195 9.331-2.93-8.598-3.859-10.553c-.928-1.954-4.592-6.351-6.156-4.934-1.563 1.417 1.368 12.605 1.368 12.605s-8.647-7.329-10.112-7.231c-1.417.098-6.107-1.27-4.495 2.003"
      />
      <path
        fill="#16084E"
        d="M61.578 194.071c-8.843 0-18.516-6.058-19.102-6.4l-.342-.244 7.279-36.836c-4.201-4.543-32.39-34.882-33.367-36.885-.489-.977-.538-1.758-.147-2.296.733-1.026 2.443-.83 4.006-.635.489.049.928.098 1.173.098h.049c1.319 0 6.009 3.664 9.038 6.155-.88-3.664-2.15-10.063-.684-11.334.293-.244.781-.537 1.563-.39 2.052.39 4.788 3.859 5.57 5.52.537 1.075 1.66 4.153 2.54 6.742-.05-1.857-.098-3.957-.098-5.228 0-1.416.733-1.905 1.172-2.051 1.466-.538 3.713.781 5.081 2.345 1.71 2.003 3.176 12.897 3.518 15.779l13.484.245c-.49-3.078-2.199-14.705-2.004-19.102.098-2.199 1.27-3.078 1.955-3.42 1.172-.537 2.735-.391 3.908.489 1.026.781 1.514 1.856 1.319 3.077-.293 1.906 3.175 9.331 6.253 15.927 1.906 4.103 3.909 8.354 5.13 11.578 1.466 3.762.733 9.82-2.1 18.076-1.906 5.569-4.202 10.308-4.593 11.09l-1.905 24.964-.196.147c-2.443 1.856-5.423 2.589-8.5 2.589m-18.028-7.23c2.54 1.514 17.49 9.917 25.551 3.713l1.857-24.672.048-.097c.098-.196 9.869-19.933 6.693-28.287-1.22-3.175-3.175-7.426-5.08-11.48-4.153-8.892-6.644-14.461-6.351-16.611.146-.977-.489-1.612-.83-1.856-.783-.586-1.857-.733-2.59-.342-.782.342-1.173 1.172-1.27 2.345-.245 4.836 2.1 19.444 2.1 19.59l.098.733-16.073-.293-.049-.537c-.635-5.277-2.1-14.217-3.273-15.634-1.221-1.416-2.98-2.247-3.713-2.003-.244.098-.342.391-.342.88 0 2.735.196 9.233.196 9.282l.146 4.201-1.368-4.006c-.049-.097-2.931-8.598-3.86-10.454-.83-1.661-3.272-4.593-4.69-4.837-.292-.049-.39 0-.488.098-.684.635 0 6.106 1.564 11.969l.488 1.905-1.514-1.27c-3.957-3.371-8.794-6.986-9.673-7.084-.342 0-.83-.049-1.368-.097-.83-.098-2.54-.294-2.834.146-.098.098-.049.44.244 1.026.733 1.466 20.373 22.815 33.417 36.836l.195.244z"
      />
      <path
        fill="#090320"
        d="M61.578 194.071c-8.843 0-18.516-6.058-19.102-6.4l-.342-.244 7.279-36.836c-4.201-4.543-32.39-34.882-33.367-36.885-.489-.977-.538-1.758-.147-2.296.733-1.026 2.443-.83 4.006-.635.489.049.928.098 1.173.098h.049c1.319 0 6.009 3.664 9.038 6.155-.88-3.664-2.15-10.063-.684-11.334.293-.244.781-.537 1.563-.39 2.052.39 4.788 3.859 5.57 5.52.537 1.075 1.66 4.153 2.54 6.742-.05-1.857-.098-3.957-.098-5.228 0-1.416.733-1.905 1.172-2.051 1.466-.538 3.713.781 5.081 2.345 1.71 2.003 3.176 12.897 3.518 15.779l13.484.245c-.49-3.078-2.199-14.705-2.004-19.102.098-2.199 1.27-3.078 1.955-3.42 1.172-.537 2.735-.391 3.908.489 1.026.781 1.514 1.856 1.319 3.077-.293 1.906 3.175 9.331 6.253 15.927 1.906 4.103 3.909 8.354 5.13 11.578 1.466 3.762.733 9.82-2.1 18.076-1.906 5.569-4.202 10.308-4.593 11.09l-1.905 24.964-.196.147c-2.443 1.856-5.423 2.589-8.5 2.589m-18.028-7.23c2.54 1.514 17.49 9.917 25.551 3.713l1.857-24.672.048-.097c.098-.196 9.869-19.933 6.693-28.287-1.22-3.175-3.175-7.426-5.08-11.48-4.153-8.892-6.644-14.461-6.351-16.611.146-.977-.489-1.612-.83-1.856-.783-.586-1.857-.733-2.59-.342-.782.342-1.173 1.172-1.27 2.345-.245 4.836 2.1 19.444 2.1 19.59l.098.733-16.073-.293-.049-.537c-.635-5.277-2.1-14.217-3.273-15.634-1.221-1.416-2.98-2.247-3.713-2.003-.244.098-.342.391-.342.88 0 2.735.196 9.233.196 9.282l.146 4.201-1.368-4.006c-.049-.097-2.931-8.598-3.86-10.454-.83-1.661-3.272-4.593-4.69-4.837-.292-.049-.39 0-.488.098-.684.635 0 6.106 1.564 11.969l.488 1.905-1.514-1.27c-3.957-3.371-8.794-6.986-9.673-7.084-.342 0-.83-.049-1.368-.097-.83-.098-2.54-.294-2.834.146-.098.098-.049.44.244 1.026.733 1.466 20.373 22.815 33.417 36.836l.195.244z"
      />
      <path
        fill="#16084E"
        d="M61.48 148.442c-.293 0-.537-.196-.635-.489-.44-2.296-5.227-8.452-7.817-10.846-.342-.342-.733-.635-1.075-.977-2.247-2.003-4.348-3.859-4.348-7.181 0-.342.294-.635.636-.635s.635.293.635.635c0 2.736 1.71 4.299 3.908 6.204.342.342.733.635 1.075.977 2.491 2.296 7.67 8.696 8.207 11.53.05.342-.146.684-.488.733 0 .048-.05.049-.098.049"
      />
      <path
        fill="#090320"
        d="M61.48 148.442c-.293 0-.537-.196-.635-.489-.44-2.296-5.227-8.452-7.817-10.846-.342-.342-.733-.635-1.075-.977-2.247-2.003-4.348-3.859-4.348-7.181 0-.342.294-.635.636-.635s.635.293.635.635c0 2.736 1.71 4.299 3.908 6.204.342.342.733.635 1.075.977 2.491 2.296 7.67 8.696 8.207 11.53.05.342-.146.684-.488.733 0 .048-.05.049-.098.049"
      />
      <path
        fill="#16084E"
        d="M39.642 134.762a.647.647 0 0 1-.635-.635l.196-12.555c0-.342.293-.635.635-.635s.635.293.635.635l-.195 12.555a.647.647 0 0 1-.636.635"
      />
      <path
        fill="#090320"
        d="M39.642 134.762a.647.647 0 0 1-.635-.635l.196-12.555c0-.342.293-.635.635-.635s.635.293.635.635l-.195 12.555a.647.647 0 0 1-.636.635"
      />
      <path
        fill="#16084E"
        d="M33.536 128.069a.58.58 0 0 1-.587-.488l-3.273-13.093a.63.63 0 0 1 .44-.782.63.63 0 0 1 .781.44l3.274 13.093a.63.63 0 0 1-.44.782c-.098.048-.147.048-.196.048"
      />
      <path
        fill="#090320"
        d="M33.536 128.069a.58.58 0 0 1-.587-.488l-3.273-13.093a.63.63 0 0 1 .44-.782.63.63 0 0 1 .781.44l3.274 13.093a.63.63 0 0 1-.44.782c-.098.048-.147.048-.196.048"
      />
      <path
        fill="#16084E"
        d="M144.141 134.372c-.049 0-.147 0-.196-.049-3.517-1.222-13.288-3.86-16.024-1.466-.244.244-.684.195-.879-.049-.244-.244-.195-.684.049-.879 1.661-1.417 5.129-1.661 10.21-.635 3.713.733 6.889 1.856 7.035 1.905.342.098.489.489.391.782a.64.64 0 0 1-.586.391"
      />
      <path
        fill="#090320"
        d="M144.141 134.372c-.049 0-.147 0-.196-.049-3.517-1.222-13.288-3.86-16.024-1.466-.244.244-.684.195-.879-.049-.244-.244-.195-.684.049-.879 1.661-1.417 5.129-1.661 10.21-.635 3.713.733 6.889 1.856 7.035 1.905.342.098.489.489.391.782a.64.64 0 0 1-.586.391"
      />
      <path
        fill="#16084E"
        d="M145.509 126.799c-.049 0-.147 0-.196-.049-.097-.049-11.334-3.419-16.122-.244-.293.195-.683.098-.879-.195s-.098-.684.196-.88c5.276-3.517 16.708-.049 17.196.098.342.098.538.44.44.782a.66.66 0 0 1-.635.488"
      />
      <path
        fill="#090320"
        d="M145.509 126.799c-.049 0-.147 0-.196-.049-.097-.049-11.334-3.419-16.122-.244-.293.195-.683.098-.879-.195s-.098-.684.196-.88c5.276-3.517 16.708-.049 17.196.098.342.098.538.44.44.782a.66.66 0 0 1-.635.488"
      />
      <path
        fill="#16084E"
        d="M148.049 119.666h-.147l-4.934-1.123c-.342-.098-.537-.391-.488-.733.097-.342.39-.537.732-.488l4.935 1.123c.342.098.537.391.488.733a.58.58 0 0 1-.586.488"
      />
      <path
        fill="#090320"
        d="M148.049 119.666h-.147l-4.934-1.123c-.342-.098-.537-.391-.488-.733.097-.342.39-.537.732-.488l4.935 1.123c.342.098.537.391.488.733a.58.58 0 0 1-.586.488"
      />
      <path
        fill="#16084E"
        d="M129.289 142.09c-.293 0-.537-.195-.586-.439-.635-2.248 0-3.469.586-4.055.977-.977 2.443-1.026 3.224-.831 1.222.342 5.863 2.003 6.058 2.052.342.098.489.489.391.782-.098.342-.488.488-.782.391-.048 0-4.787-1.71-6.009-2.003-.488-.147-1.416-.049-2.003.488-.684.733-.537 1.954-.293 2.834a.626.626 0 0 1-.439.781z"
      />
      <path
        fill="#090320"
        d="M129.289 142.09c-.293 0-.537-.195-.586-.439-.635-2.248 0-3.469.586-4.055.977-.977 2.443-1.026 3.224-.831 1.222.342 5.863 2.003 6.058 2.052.342.098.489.489.391.782-.098.342-.488.488-.782.391-.048 0-4.787-1.71-6.009-2.003-.488-.147-1.416-.049-2.003.488-.684.733-.537 1.954-.293 2.834a.626.626 0 0 1-.439.781z"
      />
      <path
        fill="#8941E8"
        d="M68.27 80.681a21 21 0 0 0 .245-3.078c0-11.578-9.38-20.958-20.959-20.958s-20.909 9.429-20.909 20.958c0 7.475 3.908 14.021 9.82 17.734.146.098 15.34 10.846 16.952 13.875l16.952-9.576s-2.198-12.946-2.1-18.955"
      />
      <path
        fill="#16084E"
        d="M70.176 118.787c-4.983 2.883-11.334 1.173-14.217-3.81l-3.517-6.107 18.027-10.406 3.518 6.107c2.882 4.983 1.172 11.334-3.811 14.216"
      />
      <path
        fill="#fff"
        d="M59.33 104.668a.63.63 0 0 1-.586-.39c-1.612-3.616-3.175-6.84-4.836-9.869-.196-.342-.342-.635-.538-.977-.488-.88-.977-1.856-1.563-2.736-1.172.782-2.492 1.417-3.908 1.661-2.736.489-5.472-1.075-6.302-3.615-.342-1.026-.196-1.954.341-2.492.44-.488 1.075-.635 1.564-.683 2.443-.342 5.08.342 7.084 1.905.537.44 1.026.928 1.514 1.514a21 21 0 0 0 1.857-1.612c.146-.146.293-.293.44-.39a51 51 0 0 1-1.027-1.71l-.342-.49c-1.172-1.807-2.003-3.028-2.1-6.008-.098-2.15 1.368-4.593 3.273-5.374 1.319-.586 2.638-.293 3.664.781 1.075 1.124 1.66 2.785 1.612 4.69-.049 2.785-1.368 5.765-3.566 8.257 2.833 4.788 8.158 13.972 8.451 14.461.196.293.05.684-.244.879-.293.195-.684.049-.88-.244-.048-.098-5.324-9.234-8.255-14.168a1 1 0 0 1-.245.195c-.586.538-1.27 1.173-2.003 1.71.635.929 1.173 1.955 1.661 2.883.196.342.342.635.538.977a136 136 0 0 1 4.885 9.966c.147.293 0 .684-.342.831 0 0-.098.048-.146.048M44.773 86.69c-.342 0-.733.049-1.075.098-.293.049-.684.098-.88.293-.243.293-.194.83-.048 1.27.635 1.906 2.834 3.176 4.885 2.785 1.222-.244 2.345-.782 3.42-1.466-.39-.488-.781-.88-1.221-1.27a8.38 8.38 0 0 0-5.08-1.71m10.7-12.409a1.86 1.86 0 0 0-.831.196c-1.417.586-2.59 2.54-2.492 4.201.098 2.59.733 3.566 1.905 5.325l.342.489c.196.293.489.781.88 1.416 1.856-2.198 2.93-4.787 2.98-7.132.049-1.563-.391-2.931-1.27-3.81-.489-.44-.977-.685-1.515-.685"
      />
      <path
        fill="#8941E8"
        d="M67.343 61.14c-.245 0-.44-.098-.636-.294-.293-.342-.293-.879.098-1.172l6.79-5.96c.343-.293.88-.293 1.173.098.293.341.293.879-.097 1.172l-6.791 5.96c-.098.147-.342.195-.537.195M72.13 68.517a.87.87 0 0 1-.781-.489.84.84 0 0 1 .44-1.124l8.598-3.615a.84.84 0 0 1 1.123.44.84.84 0 0 1-.44 1.124l-8.598 3.615c-.146.048-.244.048-.342.048M81.12 77.799c-.098 0-.147 0-.245-.05l-7.865-2.54a.857.857 0 0 1-.538-1.074.857.857 0 0 1 1.075-.538l7.866 2.54c.44.147.684.636.537 1.076a.88.88 0 0 1-.83.586"
      />
    </g>
  </svg>
);


export const SpLogo = (props) => {
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    width={142}
    height={32}
    fill="none"
    {...props}
  >
    <path
      fill="#9747FF"
      d="m26.173 4.98.844.673c-.852.117-1.673.344-2.47.623-2.592.91-5.099 2.039-7.373 3.648-1.342.95-2.554 2.073-3.394 3.563-.684 1.212-1.006 2.516-1.135 3.864-.09.929-.008 1.83.145 2.71l-.46-.437-.02-.019c-.706-.673-1.417-1.35-2.147-2.001-2.35-2.093-4.875-3.953-7.61-5.526l.204-.276.042-.057c.336-.456.668-.906 1.015-1.35 1.805-2.303 3.863-4.329 6.282-5.967A22.285 22.285 0 0 1 19.432.786l.603-.09.576-.086h.108l.736.592.011.008c.784.63 1.568 1.261 2.353 1.89l2.354 1.88Z"
    />
    <path
      fill="#6B32B5"
      d="m6.183 27.118-.954-.76c1.049-.145 2.047-.455 3.013-.813 2.314-.859 4.565-1.888 6.625-3.313 1.244-.86 2.394-1.86 3.265-3.162a8.45 8.45 0 0 0 1.41-3.963 15.83 15.83 0 0 0 .045-2.298v-.001a6.077 6.077 0 0 0-.12-.851l.462.438c.72.686 1.446 1.377 2.19 2.04 2.34 2.083 4.855 3.931 7.576 5.496l-.252.34c-.338.46-.673.913-1.023 1.36-1.845 2.349-3.95 4.41-6.435 6.061a22.296 22.296 0 0 1-9.169 3.531h-.005c-.392.06-.784.118-1.175.176h-.108l-.632-.508c-.65-.524-1.301-1.048-1.954-1.57l-2.759-2.203Z"
    />
    <path
      fill="#181529"
      d="M48.944 25.728c4.053 0 6.898-1.924 6.898-5.31 0-2.672-1.672-4.17-5.512-5.203l-1.955-.534c-2.311-.642-3.058-.998-3.058-1.925 0-.855.747-1.318 1.991-1.318 2.027 0 4.16.962 5.867 2.744l2.275-3.421c-1.209-1.533-3.946-3.172-7.715-3.172-4.267 0-7.04 2.031-7.04 5.452 0 3.065 2.275 4.313 5.404 5.132l2.347.57c1.849.463 2.844.82 2.844 1.747 0 .926-.817 1.354-2.204 1.354-2.631 0-5.156-1.533-6.613-3.1L40.126 22.2c1.493 2.102 4.942 3.528 8.818 3.528Zm12.598.036c3.556 0 5.084-2.637 5.653-4.918v1.176c0 2.494 1.707 3.35 3.592 3.35h2.986v-4.063h-1.03c-.64 0-.925-.25-.925-1.033v-5.987c0-4.384-2.56-6.736-6.97-6.736a11.35 11.35 0 0 0-8.355 3.635l2.56 3.707c1.21-1.747 3.236-2.994 5.44-2.994 1.92 0 2.596.82 2.773 2.28l-5.333 1.996c-3.306 1.14-4.978 2.673-4.978 5.31 0 2.816 1.885 4.277 4.587 4.277Zm.107-5.025c0-.677.462-1.176 1.849-1.746l3.697-1.533v1.212c0 2.138-1.35 3.35-3.768 3.35-1.21 0-1.778-.463-1.778-1.283ZM80.549 32V20.418c.463 2.816 1.707 5.31 5.334 5.31 4.266 0 6.897-3.314 6.897-9.087 0-5.809-2.63-9.123-6.684-9.123-3.627 0-5.049 2.53-5.547 5.345V7.91h-5.084V32h5.084Zm0-14.433V15.68c0-2.851 1.174-4.17 3.307-4.17 2.31 0 3.769 1.818 3.769 5.132 0 3.314-1.458 5.131-3.769 5.131-2.133 0-3.307-1.354-3.307-4.205Zm18.008 8.197c3.556 0 5.085-2.637 5.653-4.918v1.176c0 2.494 1.707 3.35 3.592 3.35h2.986v-4.063h-1.031c-.64 0-.924-.25-.924-1.033v-5.987c0-4.384-2.56-6.736-6.969-6.736a11.35 11.35 0 0 0-8.356 3.635l2.56 3.707c1.21-1.747 3.236-2.994 5.44-2.994 1.92 0 2.596.82 2.774 2.28l-5.334 1.996c-3.306 1.14-4.978 2.673-4.978 5.31 0 2.816 1.885 4.277 4.587 4.277Zm.107-5.025c0-.677.462-1.176 1.849-1.746l3.697-1.533v1.212c0 2.138-1.351 3.35-3.768 3.35-1.21 0-1.778-.463-1.778-1.283Z"
    />
    <rect
      width={25.085}
      height={15.543}
      x={116.788}
      fill="#F5EDFF"
      rx={2.771}
    />
    <path
      fill="#9747FF"
      d="M123.753 11.318h-.931V5.722h.931v1.081c.133-.632.49-1.172 1.297-1.172.981 0 1.613.806 1.613 2.112 0 1.305-.632 2.111-1.63 2.111-.806 0-1.147-.54-1.28-1.164v2.628Zm0-3.326c0 .665.274 1.106.931 1.106.582 0 1.023-.44 1.023-1.355s-.441-1.356-1.023-1.356c-.657 0-.931.441-.931 1.106v.5Zm4.62 1.862c-.665 0-1.064-.374-1.064-.956 0-.573.399-.923 1.055-1.18l1.356-.574c-.034-.407-.217-.682-.774-.682-.54 0-.964.316-1.197.74l-.557-.665a2.275 2.275 0 0 1 1.846-.898c1.006 0 1.571.574 1.571 1.572v1.521c0 .183.067.241.216.241h.258v.798h-.574c-.432 0-.773-.2-.773-.781v-.3c-.125.533-.515 1.164-1.363 1.164Zm-.167-1.097c0 .233.158.374.499.374.615 0 1.031-.366 1.031-.914v-.433l-1.039.466c-.349.158-.491.3-.491.507Zm4.348 2.403c-.582 0-1.055-.3-1.28-.616l.441-.748c.216.308.465.54.839.54.316 0 .516-.14.666-.59l.074-.224h-.573l-1.339-3.8h1.023l.731 2.295.291 1.08.291-1.08.724-2.295h1.031l-1.497 4.332c-.291.815-.765 1.106-1.422 1.106Z"
    />
  </svg>
}


export const SpBackArrow = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_9943_6649)">
      <circle cx={12} cy={12} r={12} fill="#F5EDFF" />
      <path
        d="M16 12.1371C16 12.3269 15.8589 12.4838 15.6759 12.5086L15.625 12.5121L9.033 12.5118L11.4145 14.8835C11.5613 15.0297 11.5618 15.2671 11.4156 15.4138C11.2828 15.5473 11.0745 15.5598 10.9274 15.4512L10.8853 15.415L7.86031 12.403C7.84096 12.3837 7.82416 12.3629 7.8099 12.3409C7.80587 12.3342 7.80177 12.3275 7.79788 12.3205C7.79431 12.3146 7.79108 12.3085 7.78803 12.3022C7.78379 12.2932 7.77966 12.2838 7.7759 12.2743C7.77285 12.2669 7.7703 12.2598 7.76798 12.2526C7.76522 12.2436 7.76253 12.2341 7.76022 12.2244C7.75851 12.2176 7.75715 12.2111 7.75596 12.2046C7.75429 12.1949 7.75287 12.1848 7.75186 12.1747C7.75099 12.1669 7.75046 12.1592 7.75017 12.1515C7.75009 12.1468 7.75 12.142 7.75 12.1371L7.75019 12.1225C7.75047 12.1152 7.75098 12.1078 7.7517 12.1005L7.75 12.1371C7.75 12.1134 7.75219 12.0902 7.75638 12.0678C7.75736 12.0624 7.75851 12.0569 7.7598 12.0514C7.76246 12.0402 7.76554 12.0293 7.76908 12.0186C7.77082 12.0133 7.77285 12.0077 7.77502 12.0021C7.7794 11.9908 7.78416 11.98 7.7894 11.9695C7.79184 11.9646 7.79456 11.9595 7.79742 11.9544C7.8021 11.946 7.80693 11.9381 7.81204 11.9304C7.81564 11.9249 7.81963 11.9192 7.82379 11.9137L7.82704 11.9093C7.83714 11.8961 7.8481 11.8836 7.85983 11.8719L7.86028 11.8715L10.8853 8.85904C11.032 8.7129 11.2695 8.71339 11.4156 8.86014C11.5485 8.99355 11.5601 9.20191 11.4509 9.34849L11.4145 9.39047L9.034 11.7618L15.625 11.7621C15.8321 11.7621 16 11.9299 16 12.1371Z"
        fill="#9747FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_9943_6649">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);




